export const TASK_DRIVER_FIELDS = [
  "driver_id",
  "driver_name",
  "driver_email",
  "driver_phone",
  "driver_group_name",
];

export const TASK_DRIVER_FIELDS_DATA = [
  "1",
  "John Due",
  "john@mail.com",
  "000-000-0000",
  "TestGroup",
];

export const TASK_CUSTOMER_FIELDS = [
  "location_id",
  "location_name",
  "location_username",
  "location_email",
  "location_phone",
  "location_address",
  "location_address_coordinates",
];

export const TASK_CUSTOMER_FIELDS_DATA = [
  "1",
  "Location Name",
  "location@username",
  "location@example.com",
  "000-000-0000",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude - Longitude",
];

export const CUSTOMER_CSV_FIELDS = [
  "name",
  "email",
  "phoneNumber",
  "address",
  "location",
  "customerGroup",
  "description",
];

export const CUSTOMER_CSV_EXAMPLE = [
  "John Doe",
  "john@example.com",
  "0000000000",
  "14th Street Dubai-Supervisor Dubai United Arab Emirates",
  "Latitude - Longitude",
  "ExampleGroupName",
  "Write about this customer",
];

export const FLEET_SERVICE_EXPENDITURE_CSV_FIELDS = [
  "device_id",
  "device_name",
  "service_name",
  "service_odometer_value",
  "service_material_cost",
  "service_cost",
  "service_total_cost",
  "service_invoice",
  "service_comments",
  "service_workshop",
  "service_date",
];

export const CREATE_FLEET_SERVICE_EXPENDITURE_CSV_VALUES = (fields, vehicles) => {
  try {
    const values = [];

    if (!vehicles) return [fields];

    const defaultValues = [
      "Service Name",
      0,
      0,
      0,
      0,
      "Invoice No",
      "Comments",
      "Service Workshop",
      "DD-MM-YYYY",
    ]

    for (const [key, value] of Object.entries(vehicles)) {
      values.push([key, value.name, ...defaultValues])
    }

    // returning array of arrays, don't spread fields
    return [fields, ...values];
  } catch (error) {
    console.log(`ERROR IN CREATING FLEET SERVICE EXPENDITURE CSV VALUES :: ${error?.message}`);
    return [fields];
  }
}

export const FLEET_FUEL_EXPENDITURE_CSV_FIELDS = [
  "device_id",
  "device_name",
  "driver_id",
  "driver_name",
  "fuel_date",
  "fuel_type",
  "fuel_quantity",
  "fuel_amount",
  "fuel_odometer",
  "fuel_comments",
];

export const CREATE_FLEET_FUEL_EXPENDITURE_CSV_VALUES = (fields, vehicles, drivers) => {
  
  try {
    if (!vehicles || !drivers) return [fields];
    
    const values = [];
  
    const defaultValues = [
      "DD-MM-YYYY",
      "Petrol/Gas/Diesel",
      0,
      0,
      0,
      "comments"
    ];
  
    for (const [key, value] of Object.entries(vehicles)) {
      // null :: Placeholder for driver_id and driver_name
      values.push([key, value.name, "", "", ...defaultValues])
    }
  
    drivers?.forEach(({ driver_id, driver_name }, index) => {
      if(index < values?.length){
        values[index][2] = driver_id;
        values[index][3] = driver_name
      }else{
        values.push(["", "", driver_id, driver_name, ...defaultValues])
      }
    })
  
    // returning array of arrays, don't spread fields
    return [fields, ...values];
  } catch (error) {
    console.log(`ERROR IN CREATING FLEET SERVICE EXPENDITURE CSV VALUES :: ${error?.message}`);
    return [fields];
  }
}
