import axios from "axios";
import { API_URLS } from "../configs/api.urls";
import { getStorage, setStorage } from "./localStorageService";

export const BASE_URL = "";
// const API_URL = 'http://localhost:9090/task-manager/api/v1';
const API_URL = API_URLS.TASK_MANAGER_URL;
export const SOCKET_URL = "";

export const makeId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const TaskManagerRequest = async (
  path,
  data = {},
  isMediaUpload = false,
  button = false,
  apiToken = "",
) => {
  const activeUser = await getStorage("__user__", "object");
  var token = activeUser
    ? makeId(100) + "__" + activeUser.organisation.id + "__" + activeUser.id
    : "UnAuthorized";
  const headers = { "X-master-auth": token, "client-type": "locatorWeb" };

  if(apiToken){
    headers["Authorization"] = apiToken;
  }

  if (isMediaUpload) {
    headers["content-type"] = "application/x-www-form-urlencoded";
  }

  var buttonText = "";

  if (button) {
    var elem = document.getElementById(button);

    if (elem) {
      buttonText = elem.textContent || elem.innerText;
      elem.innerHTML = `<i class="fa fa-spinner fa-spin"> </i> Loading...`;
      elem.disabled = true;
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}${path}`, data, { headers: headers })
      .then(function (response) {
        try {
          if (response.data.code == 401) {
            window.location.href = "/login";
            throw new Error("Unauthorized");
          }
          if (response.data.code == 503) {
            setStorage("maintenanceData", response.data.data, "object");
            window.location.href = "/maintenance";
          }
          if (response.data.code !== 200 && response.data.code !==201) {
            throw new Error(response.data.data);
          }
          resolve(response.data.data);
        } catch (error) {
          reject(error.message);
        } finally {
          if (button) {
            var elem = document.getElementById(button);
            if (elem) {
              elem.innerHTML = buttonText;
              elem.disabled = false;
            }
          }
        }
      })
      .catch(function (error) {
        console.log("Error : ", error);
        reject(error.message);
        if (button) {
          var elem = document.getElementById(button);
          if (!elem) return;
          elem.innerHTML = buttonText;
          elem.disabled = false;
        }
      });
  });
};

export default TaskManagerRequest;
