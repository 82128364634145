import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import Default from "../Default";
import { useHistory } from "react-router-dom";
import { getStorage, setStorage } from "../../services/localStorageService";

const Settings = (props) => {
  const history = useHistory();
  const [active, setActive] = useState(getStorage("setting-tab"));

  const closeSettingHandler = () => {
    history.goBack();
  };

  const setActiveTabHandler = (value) => {
    setActive(value);
    setStorage("setting-tab", value);
    history.replace("/settings/" + value);
  };

  return (
    <Default>
      <div
        className="row"
        style={{ ...styles.container, ...props.containerStyle }}
      >
        <div className="col-md-12 p-0">
          <p style={styles.head} className="bg-light-gray">
            Settings
            <i
              className="fa fa-times float-right pointer"
              onClick={closeSettingHandler}
            ></i>
          </p>

          <div className="row m-0">
            <div className="col-md-2 p-0 bg-white">
              <ul className="setting-navbar font-12">
                <li
                  className={`${
                    active == "profile" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("profile")}
                >
                  {active != "profile" && (
                    <img
                      src={
                        require("../../assets/img/settings/profile.svg").default
                      }
                    />
                  )}
                  {active == "profile" && (
                    <img
                      src={
                        require("../../assets/img/settings/profile_active.svg")
                          .default
                      }
                    />
                  )}
                  My Profile
                </li>

                <li
                  className={`${
                    active == "themes" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("themes")}
                >
                  {active != "themes" && (
                    <img
                      src={
                        require("../../assets/img/settings/profile.svg").default
                      }
                    />
                  )}
                  {active == "themes" && (
                    <img
                      src={
                        require("../../assets/img/settings/profile_active.svg")
                          .default
                      }
                    />
                  )}
                  Themes
                </li>

                <li
                  className={`${
                    active == "account" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("account")}
                >
                  {active != "account" && (
                    <img
                      src={
                        require("../../assets/img/settings/settings.svg")
                          .default
                      }
                    />
                  )}
                  {active == "account" && (
                    <img
                      src={
                        require("../../assets/img/settings/settings_active.svg")
                          .default
                      }
                    />
                  )}
                  Account Settings
                </li>

                <li
                  className={`${active == "live" ? "active-setting-tab" : ""}`}
                  onClick={() => setActiveTabHandler("live")}
                >
                  {active != "live" && (
                    <img
                      src={
                        require("../../assets/img/settings/live.svg").default
                      }
                    />
                  )}
                  {active == "live" && (
                    <img
                      src={
                        require("../../assets/img/settings/live_active.svg")
                          .default
                      }
                    />
                  )}
                  Live Settings
                </li>

                <li
                  className={`${
                    active == "dashboard" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("dashboard")}
                >
                  {active != "dashboard" && (
                    <img
                      src={
                        require("../../assets/img/settings/dashboard.svg")
                          .default
                      }
                    />
                  )}
                  {active == "dashboard" && (
                    <img
                      src={
                        require("../../assets/img/settings/dashboard_active.svg")
                          .default
                      }
                    />
                  )}
                  Dashboard Settings
                </li>

                <li
                  className={`${active == "toll" ? "active-setting-tab" : ""}`}
                  onClick={() => setActiveTabHandler("toll")}
                >
                  {active != "toll" && (
                    <img
                      src={
                        require("../../assets/img/settings/toll.png").default
                      }
                      style={{ height: 16 }}
                    />
                  )}
                  {active == "toll" && (
                    <img
                      src={
                        require("../../assets/img/settings/toll_active.png")
                          .default
                      }
                      style={{ height: 16 }}
                    />
                  )}
                  Tolls Settings
                </li>

                <li
                  className={`${
                    active == "task-manager" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("task-manager")}
                >
                  {active != "task-manager" && (
                    <img
                      src={
                        require("../../assets/img/settings/task.svg").default
                      }
                    />
                  )}
                  {active == "task-manager" && (
                    <img
                      src={
                        require("../../assets/img/settings/task_active.svg")
                          .default
                      }
                    />
                  )}
                  Task Manager Settings
                </li>

                <li
                  className={`${
                    active == "inspection" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("inspection")}
                >
                  {active != "inspection" && (
                    <img
                      src={
                        require("../../assets/img/settings/inspection.png")
                          .default
                      }
                    />
                  )}
                  {active == "inspection" && (
                    <img
                      src={
                        require("../../assets/img/settings/inspection_active.png")
                          .default
                      }
                    />
                  )}
                  Inspection Settings
                </li>

                <li
                  className={`${
                    active === "community" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("community")}
                >
                  {active !== "community" && (
                    <img
                      src={
                        require("../../assets/img/settings/community.png")
                          .default
                      }
                    />
                  )}
                  {active === "community" && (
                    <img
                      src={
                        require("../../assets/img/settings/community_active.png")
                          .default
                      }
                    />
                  )}
                  Community Settings
                </li>

                <li
                  className={`${
                    active == "notification" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("notification")}
                >
                  {active != "notification" && (
                    <img
                      src={
                        require("../../assets/img/settings/notification.svg")
                          .default
                      }
                    />
                  )}
                  {active == "notification" && (
                    <img
                      src={
                        require("../../assets/img/settings/notification_active.svg")
                          .default
                      }
                    />
                  )}
                  Notifications
                </li>

                <li
                  className={`${
                    active == "report" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("report")}
                >
                  {active != "report" && (
                    <img
                      src={
                        require("../../assets/img/settings/report.svg").default
                      }
                    />
                  )}
                  {active == "report" && (
                    <img
                      src={
                        require("../../assets/img/settings/report_active.svg")
                          .default
                      }
                    />
                  )}
                  Report Schedular
                </li>

                <li
                  className={`${
                    active == "fleet-manager" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("fleet-manager")}
                >
                  {active != "fleet-manager" && (
                    <img
                      src={
                        require("../../assets/img/settings/fleet_manager.png")
                          .default
                      }
                      height={15}
                    />
                  )}
                  {active == "fleet-manager" && (
                    <img
                      src={
                        require("../../assets/img/settings/fleet_manager_active.png")
                          .default
                      }
                      height={15}
                    />
                  )}
                  Fleet Manager Settings
                </li>

                <li
                  className={`${
                    active == "expenceManager" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("expenceManager")}
                >
                  {active != "expenceManager" && (
                    <img
                      src={
                        require("../../assets/img/settings/expense.png").default
                      }
                    />
                  )}
                  {active == "expenceManager" && (
                    <img
                      src={
                        require("../../assets/img/settings/expense_active.png")
                          .default
                      }
                    />
                  )}
                  Expense Manager
                </li>

                <li
                  className={`${
                    active == "storage-summary" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("storage-summary")}
                >
                  {active != "storage-summary" && (
                    <img
                      src={
                        require("../../assets/img/settings/storage.png").default
                      }
                    />
                  )}
                  {active == "storage-summary" && (
                    <img
                      src={
                        require("../../assets/img/settings/storage_active.png")
                          .default
                      }
                    />
                  )}
                  Storage Summary
                </li>

                <li
                  className={`${
                    active == "other-settings" ? "active-setting-tab" : ""
                  }`}
                  onClick={() => setActiveTabHandler("other-settings")}
                >
                  {active != "other-settings" && (
                    <img
                      src={
                        require("../../assets/img/settings/settings.svg")
                          .default
                      }
                    />
                  )}
                  {active == "other-settings" && (
                    <img
                      src={
                        require("../../assets/img/settings/settings_active.svg")
                          .default
                      }
                    />
                  )}
                  Other Settings
                </li>
              </ul>
            </div>
            <div
              className="col-md-10 pr-0 bg-light-gray"
              style={{
                ...styles.contentContainer,
                ...props.contentContainerStyle,
              }}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </Default>
  );
};

const styles = {
  container: {
    borderRadius: 5,
    overflowY: "auto",
    backgroundColor: "#f8f8f8",
    width: "99.5%",
    height: "100%",
    margin: 0,
    paddingBottom: 100,
  },
  head: {
    padding: "15px",
    borderBottom: "1px solid #e2e2e2",
    fontSize: "14px",
    marginBottom: "0px",
    backgroundColor: "#f3f7fa",
  },
  contentContainer: {
    height: "82vh",
    overflowY: "auto",
  },
};

export default Settings;
