import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DragableMap from "../../UI/DragableMap";
import Select from "../../UI/Select";
import AddCustomerModal from "./AddCustomerModal";
import CalendarField from "../../UI/CalendarField";
import { Container } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addTask,
  getDriver,
  getTaskAttributes,
  getTaskCheckSettings,
  getTaskDrivers,
  getTasks,
  getDriverTaskStatusCount,
} from "../../../store/actions/taskManager.action";
import { getTaskSettings } from "../../../store/actions/setting.action";
import MakeAttribute from "./MakeAttributes/MakeAttribute";
import { ShowNotification } from "../../../services/Notifications";
import TimePicker from "../../UI/TimePicker";
import TaskManagerRequest from "../../../services/TaskManagerRequest";
import { getStorage } from "../../../services/localStorageService";
import Autocomplete from "react-google-autocomplete";
import TaskStatusList from "../../taskManager/FieldsCard/TaskStatusList";
import _getCurrentLocation from "../../../configs/current-location";
import RepeatTaskModal from "./RepeatTaskModal";
import { FaMapMarkerAlt, FaRegKeyboard } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { GOOGLE_MAPS_API_KEY } from "../../../configs/map.key";
import ChooseAddressFromMapModel from "../settings/task-manager/FielsStaffModal/ChooseAddressFromMapModel";
import StaticMap from "../../UI/StaticMap";
import { default as ReactSelect } from "react-select";
import { dubaiCoordinates, isValidMomentDate } from "../../../configs/common";
import CustomerSelect from "../../UI/CustomerSelect";
import { HelpIcon } from "../../tooltips/HelpIcon";

const CreateTaskModal = (props) => {
  const dispatch = useDispatch();

  const { isStaffSelected = false } = props;

  const customers = useSelector((state) => state.taskReducer.customers);
  const taskAttributes = useSelector(
    (state) => state.taskReducer.taskAttributes
  );
  const activeDriver = useSelector((state) => state.taskReducer.activeDriver);
  const selectedDrivers = useSelector(
    (state) => state.taskReducer.selectedDrivers
  );
  const selectedGroups = useSelector(
    (state) => state.taskReducer.selectedGroups
  );
  const time = useSelector((state) => state.taskReducer.time);
  const taskHeaderSwitchOn = useSelector(
    (state) => state.taskReducer.taskHeaderSwitchOn
  );
  const taskDrivers = useSelector((state) => state.taskReducer.taskDrivers);
  const taskDriverGroups = useSelector(
    (state) => state.taskReducer.taskDriverGroups
  );
  const taskCheckSetting = useSelector(
    (state) => state.taskReducer.taskCheckSetting
  );
  const _taskSettings = useSelector(
    (state) => state.settingReducer.taskSettings
  );

  const [isModal, setIsModal] = useState(false);

  const [date1, setDate1] = useState(new Date());
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [status, setStatus] = useState("Not Assigned");

  const [position, setPosition] = useState(dubaiCoordinates);

  const [dropPosition, setDropPosition] = useState(dubaiCoordinates);
  const [pickupPosition, setPickupPosition] = useState(dubaiCoordinates);

  const [dropLocationPositionValue, setDropLocationPositionValue] =
    useState("");
  const [pickupLocationPositionValue, setPickupLocationPositionValue] =
    useState("");

  const [dropAddress, setDropAddress] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");

  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");

  const [startPosition, setStartPosition] = useState(dubaiCoordinates);
  const [endPosition, setEndPosition] = useState(dubaiCoordinates);

  const [search, setSearch] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(false);
  const [selectedDriverGroup, setSelectedDriverGroup] = useState(false);
  const [staff, setStaff] = useState("Driver"); //task_client_address
  const [assignAs, setAssignAs] = useState("separately"); //task_client_address

  const [allowFields, setAllowFields] = useState(false);
  const [taskSettings, setTaskSettings] = useState(_taskSettings);

  const [isLoading, setLoading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const [isStartAddressModel, setStartAddressModel] = useState(false);
  const toggleStartAddressModel = () =>
    setStartAddressModel(!isStartAddressModel);

  const [isEndAddressModel, setEndAddressModel] = useState(false);
  const toggleEndAddressModel = () => setEndAddressModel(!isEndAddressModel);

  const toggleType = () => setIsAddress((prv) => !prv);

  const toggleModal = (res = false) => {
    if (res) {
      handleSetCustomer(res);
    }
    setIsModal(!isModal);
  };

  const tomorrow = new Date(new Date());
  tomorrow.setDate(tomorrow.getDate() + 1);

  const onSetPosition = (data) => {
    setPosition(data.position);
    setSearch(`${data.position.lat},${data.position.lng}`);
  };

  const handleCloseModal = () => {
    setSelectedCustomer(false);
    props.toggleModal();
  };

  const onSetDropPosition = (data) => {
    setDropPosition(data.position);
    setDropAddress(data.address);
    setDropLocationPositionValue(`${data.position.lat},${data.position.lng}`);
  };

  const onSetPickupPosition = (data) => {
    setPickupPosition(data.position);
    setPickupAddress(data.address);
    setPickupLocationPositionValue(`${data.position.lat},${data.position.lng}`);
  };

  useEffect(() => {
    if (props.show == true) {
      // dispatch(getTaskDriverGroups());
      dispatch(getTaskDrivers());
      dispatch(getTaskAttributes());
      dispatch(getTaskCheckSettings());
      if (props.fromTime) {
        setFromTime(props.fromTime);
        setToTime(
          moment(props.fromTime).add(
            parseInt(
              taskSettings && taskSettings.defaultValues
                ? taskSettings.defaultValues.jobsDefaultDuration.toString()
                : ""
            ),
            "minutes"
          )
        );
      } else {
        setFromTime(new Date());
        setToTime(
          moment(new Date()).add(
            parseInt(
              taskSettings && taskSettings.defaultValues
                ? taskSettings.defaultValues.jobsDefaultDuration.toString()
                : ""
            ),
            "minutes"
          )
        );
      }
      if (props.status) setStatus(props.status);
      if (props.activeDriver) setSelectedDriver(props.activeDriver);
      setStartAddress("");
      setEndAddress("");
      setStartPosition(dubaiCoordinates);
      setEndPosition(dubaiCoordinates);
      setStaff("Driver");
    }
  }, [props.show]);

  const makeArray = (value) => {
    const cloneValue = value.toString().replaceAll("[", "");
    const parts = cloneValue.toString().split(",");
    return parts;
  };

  const handleSetCustomer = (customer) => {
    setSelectedCustomer(customer);
    if (customer.location)
      setPosition({
        lat: parseFloat(customer.location.latitude),
        lng: parseFloat(customer.location.longitude),
      });
  };

  const onSelectDriver = (event) => {
    if (event.target.value == "") setStatus("Not Assigned");
    setSelectedDriver(false);
    setSelectedDriverGroup(false);
    const index = taskDrivers.findIndex((c) => c._id == event.target.value);
    if (index !== -1) {
      setSelectedDriver(taskDrivers[index]);
      setStatus("Assigned");
    }
  };

  useEffect(() => {
    if (isStaffSelected) {
      setSelectedDriver(false);
      setSelectedDriverGroup(false);
      const index = taskDrivers.findIndex((c) => c._id == isStaffSelected);
      if (index !== -1) setSelectedDriver(taskDrivers[index]);
    }
  }, [isStaffSelected]);

  const onSelectDriverGroup = (event) => {
    setSelectedDriver(false);
    setSelectedDriverGroup(false);
    const index = taskDriverGroups.findIndex(
      (c) => c._id == event.target.value
    );
    if (index !== -1) setSelectedDriverGroup(taskDriverGroups[index]);
  };

  useEffect(() => {
    setSelectedDriver(props.activeDriver);
  }, [props.activeDriver]);

  const handleSubmit = async (event) => {
    if (isLoading) return;

    let data = {};

    try {
      setLoading(true);
      const formData = new FormData(event.target);
      event.preventDefault();

      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }

      // if (taskCheckSetting && taskCheckSetting.check_time == 1) {
      //   data[`fromTime`] = moment(fromTime).format("HH:mm");
      //   data[`toTime`] = moment(toTime).format("HH:mm");
      // } else {
      //   data[`fromTime`] = moment(date1).format("HH:mm");
      //   data[`toTime`] = moment(date1).format("HH:mm");
      // }

      if (!selectedCustomer) {
        throw new Error(`Please Select Location`);
      }

      if (_allow("time")) {
        if (!isValidMomentDate(fromTime)) {
          throw new Error(`Please enter valid from time`);
        }
        if (!isValidMomentDate(toTime)) {
          throw new Error(`Please enter valid to time`);
        }
        data[`fromTime`] = moment(fromTime).format("HH:mm");
        data[`toTime`] = moment(toTime).format("HH:mm");
      } else {
        data[`fromTime`] = "00:00";
        data[`toTime`] = "23:59";
      }

      data["taskDate"] = `${moment(date1).format("yyyy-MM-DD 00:00:00")}`;

      data.deliveryAddress = data.endAddress;
      data.endAddress = endAddress;
      data.startAddress = startAddress;

      if (_allow("uniqueId") && _allow("jobSchedular")) {
        if (data?.uniqueId && data?.repeat !== "norepeat")
          throw new Error("Enter a Unique ID or choose Repeat —use either, not both. Clear one to avoid conflicts.");
      }

      // MANAGE END ADDRESS
      if (_allow("endAddress")) {
        data.endLocation = {
          latitude: endPosition.lat,
          longitude: endPosition.lng,
        };
      } else {
        data.endLocation = { latitude: position.lat, longitude: position.lng };
      }

      if (_allow("startAddress")) {
        data.startLocation = {
          latitude: startPosition.lat,
          longitude: startPosition.lng,
        };
      } else {
        data.startLocation = {
          latitude: position.lat,
          longitude: position.lng,
        };
      }

      if (_allow("dropAndPickup")) {
        data.dropLocation = { name: dropAddress, coordinates: dropPosition };
        data.pickupLocation = {
          name: pickupAddress,
          coordinates: pickupPosition,
        };
      }

      // if (dropAddress !== "") {
      //   data.endLocation = {
      //     latitude: dropPosition.lat,
      //     longitude: dropPosition.lng,
      //   };
      // }

      data.coordinates = { latitude: position.lat, longitude: position.lng };
      var attributes = {};

      taskAttributes.map((attribute) => {
        if (attribute.type == "checkbox") {
          const _data = makeArray(attribute.value);
          _data.map((value, i) => {
            if (data[`attr_${attribute.key}_${value.trim()}`] !== undefined) {
              attributes[`${attribute.key}_${value.trim()}`] =
                data[`attr_${attribute.key}_${value.trim()}`];
              delete data[`attr_${attribute.key}_${value.trim()}`];
            }
          });
          return;
        }

        if (
          data[`attr_${attribute.key}`] !== "" &&
          data[`attr_${attribute.key}`] !== undefined
        ) {
          attributes[attribute.key] = data[`attr_${attribute.key}`];
          delete data[`attr_${attribute.key}`];
        }
      });

      data.attributes = attributes;

      const user = await getStorage("__user__", "object");

      if (user) {
        data.createdBy = {
          id: user.id,
          name: user.name,
          profile: user.photo ? user.photo : "",
        };
        data.authUserName = user.name;
      }

      // var _location = await _getCurrentLocation(false, true);
      // if (_location) data.location = _location;

      if (!data.group) data.group = "";

      if (
        data.taskStatus == "Not Assigned" &&
        (selectedDriver || data.group != "")
      )
        throw new Error(
          `You have selected driver or group, but status is 'Not Assigned'. Please select status`
        );
      if (
        data.taskStatus != "Not Assigned" &&
        !selectedDriver &&
        data.group == ""
      )
        throw new Error(
          `You have selected status '${data.taskStatus}' but driver or group is Not selected. Please select driver or group`
        );

      data.isSharedWithGroup = assignAs == "asGroup";
      if (assignAs == "asGroup") {
        delete data.driver;
      }

      const response = await TaskManagerRequest(
        "/create/task",
        data,
        false,
        "createTaskButton"
      );

      if (data.driver) dispatch(getDriver(data.driver));

      dispatch(
        getDriverTaskStatusCount({
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
        })
      );

      dispatch(
        getTasks({
          taskStatus:
            time.taskStatus == "All" || !taskHeaderSwitchOn
              ? false
              : time.taskStatus,
          startDate: `${moment(time.from).format("yyyy-MM-DD")} 00:00:00`,
          endDate: `${moment(time.to).format("yyyy-MM-DD")} 23:59:59`,
          driver: taskHeaderSwitchOn
            ? data.driver
              ? [data.driver]
              : activeDriver
                ? [activeDriver._id]
                : selectedDrivers
            : null,
          group: taskHeaderSwitchOn ? selectedGroups : null,
        })
      );

      ShowNotification("success", "Task added successfully");
      if (props.onCreateTask) props.onCreateTask();
      setSelectedCustomer(false);
      props.toggleModal();
      await loadTaskSettings();
    } catch (error) {
      ShowNotification("error", error.message ? error.message : error);
    } finally {
      setLoading(false);
    }

    data["task_assigned_date"] = moment(date1).format("DD-MM-yyyy");
    data["task_client_latitude"] = position.lat;
    data["task_client_longitude"] = position.lng;
    data["task_latitude"] = position.lat;
    data["task_longitude"] = position.lng;
    data["taskoption"] = "onetime";

    return false;

    var _attributes = {};

    taskAttributes.map((attribute) => {
      if (attribute.task_attribute_type == "checkbox") {
        attribute.task_attribute_values.map((value) => {
          if (
            data[`${attribute.task_attribute_id}-${value}`] !== undefined &&
            data[`${attribute.task_attribute_id}-${value}`] == "on"
          ) {
            _attributes[`${attribute.task_attribute_id}-${value}`] = "1";
          }
        });
      } else {
        if (
          data[attribute.task_attribute_name] !== "" &&
          data[attribute.task_attribute_name] !== undefined
        ) {
          _attributes[
            `${attribute.task_attribute_id}-${attribute.task_attribute_name}`
          ] = data[attribute.task_attribute_name];
        }
      }
    });

    data["attributes"] = _attributes;

    await dispatch(addTask(data));
    ShowNotification("success", "Task created successfully");
    dispatch(
      getTasks({
        id: activeDriver ? activeDriver._id : 0,
        from: time.from,
        to: time.to,
        taskStatus: time.taskStatus == "All" ? false : time.taskStatus,
      })
    );

    props.toggleModal();
  };

  const onSearchPosition = (type = "position") => {
    var parts = search.split(",");
    if (type == "drop") parts = dropLocationPositionValue.split(",");
    if (type == "pickup") parts = pickupLocationPositionValue.split(",");
    if (parts.length >= 2) {
      switch (type) {
        case "position":
          setPosition({
            lat: parseFloat(parts[0].trim()),
            lng: parseFloat(parts[1].trim()),
          });
          break;
        case "drop":
          setDropPosition({
            lat: parseFloat(parts[0].trim()),
            lng: parseFloat(parts[1].trim()),
          });
          break;
        case "pickup":
          setPickupPosition({
            lat: parseFloat(parts[0].trim()),
            lng: parseFloat(parts[1].trim()),
          });
          break;
      }
    }
  };

  // const onDurationChange = (event) => {
  //   setToTime(moment(fromTime).add(event.target.value, "minutes"));
  // };

  const loadTaskSettings = async () => {
    dispatch(getTaskSettings());
  };

  const handleGoogleSearch = (value, type = "position") => {
    var lat = value.geometry.location.lat();
    var lng = value.geometry.location.lng();

    switch (type) {
      case "position":
        setSearch(`${lat}, ${lng}`);
        break;
      case "drop":
        setDropLocationPositionValue(`${lat}, ${lng}`);
        break;
      case "pickup":
        setPickupLocationPositionValue(`${lat}, ${lng}`);
        break;
    }
  };

  useEffect(() => {
    loadTaskSettings();
  }, []);

  useEffect(() => {
    if (!_taskSettings) return;
    setTaskSettings(_taskSettings);
    if (_taskSettings.allowFields) setAllowFields(_taskSettings.allowFields);
  }, [_taskSettings]);

  const _allow = (key) => {
    if (allowFields && (!allowFields[key] || allowFields[key] == undefined)) {
      return false;
    } else if (allowFields && allowFields[key] && allowFields[key] == 0) {
      return false;
    } else {
      return true;
    }
  };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
    if (e.target.value == "Not Assigned") setSelectedDriver(false);
  };

  const onMapStartAddressChange = (ad) => {
    setStartAddress(ad.address);
    setStartPosition(ad.position);
  };

  const onMapEndAddressChange = (ad) => {
    setEndAddress(ad.address);
    setEndPosition(ad.position);
  };

  return (
    <>
      <AddCustomerModal
        show={isModal}
        toggleModal={(res) => toggleModal(res)}
      />

      <ChooseAddressFromMapModel
        onMapAddressChange={onMapStartAddressChange}
        position={startPosition}
        show={isStartAddressModel}
        toggleModal={toggleStartAddressModel}
      />

      <ChooseAddressFromMapModel
        onMapAddressChange={onMapEndAddressChange}
        position={endPosition}
        show={isEndAddressModel}
        toggleModal={toggleEndAddressModel}
      />

      <Modal
        show={props.show}
        dialogClassName="modal-60w"
        onHide={handleCloseModal}
        aria-labelledby="CreateTaskModal"
      >
        <Modal.Header closeButton>Create new Task</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Container
              style={{ height: "65vh", overflow: "auto" }}
              className="visible-scroll-hover"
            >
              <div className="row">
                <div className="col-12">
                  <fieldset>
                    <legend>
                      <span>Job Description</span>
                    </legend>
                    <textarea
                      name="description"
                      required={true}
                      rows="2"
                      className="textarea"
                    ></textarea>
                  </fieldset>
                </div>

                {_allow("uniqueId") && (
                  <div className="col-6">
                    <Input type="number" name="uniqueId" label="Unique Id" />
                  </div>
                )}
                {_allow("serialNumber") && (
                  <div className="col-6">
                    <Input type="number" readOnly={true} name="serialNumber" label="Serial Number" defaultValue={
                      taskSettings && taskSettings.defaultValues
                        ? taskSettings.defaultValues.currentSerialNumber
                        : ""
                    } />
                  </div>
                )}

                {/* {_allow("jobId") && (
                  <div className="col-md-6">
                    <Input type="number" name="jobId" label="Job Id" />
                  </div>
                )} */}

                <React.Fragment>
                  <div
                    className={!_allow("jobWaitingTime") ? "col-12" : "col-6"}
                  >
                    <div className="d-flex">
                      <div
                        style={{
                          // width: !_allow("jobWaitingTime") ? "85%" : "70%",
                          flex: 1,
                          marginRight: 20,
                        }}
                      >
                        <CustomerSelect
                          className="mt-3 mb-2"
                          value={selectedCustomer}
                          onChange={handleSetCustomer}
                          name="customer"
                          placeholder="Select Location"
                          closeMenuOnSelect={true}
                        />
                      </div>

                      <div className="ms-auto">
                        <IconButton
                          buttonstyle={{ padding: 10, width: 110 }}
                          label="Add Location"
                          onClick={() => toggleModal(false)}
                          className="pb-2 mt-3 mb-2 date-button"
                        />
                      </div>
                    </div>
                  </div>

                  {_allow("jobWaitingTime") && (
                    <div className="col-6">
                      <Input
                        label="Job Duration [Min]"
                        placeholder="In Minutes Ex : 5"
                        name="jobWaitingTime"
                        defaultValue={
                          taskSettings && taskSettings.defaultValues
                            ? taskSettings.defaultValues.jobsDefaultDuration
                            : ""
                        }
                        type="number"
                      />
                    </div>
                  )}
                </React.Fragment>

                {selectedCustomer && (
                  <React.Fragment>
                    <div className="col-6">
                      <div className="task-outer">
                        <div className="d-flex">
                          <span className="task_label">Name</span>
                          <span className="task_text">
                            {selectedCustomer.name}
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="task_label">Email</span>
                          <span className="task_text">
                            {selectedCustomer.email}
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="task_label">Phone</span>
                          <span className="task_text">
                            {selectedCustomer.phoneNumber}
                          </span>
                        </div>
                        <div className="d-flex">
                          <span className="task_label">Address</span>
                          <span className="task_text">
                            {selectedCustomer.address}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex mt-2">
                        {isAddress ? (
                          <>
                            <fieldset className="pb-0">
                              <legend>
                                <span>Address</span>
                              </legend>
                              <Autocomplete
                                apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
                                onPlaceSelected={(place) =>
                                  handleGoogleSearch(place)
                                }
                                className="form-control p-0 font-12 box-shadow-none"
                                placeholder="Search here..."
                                options={{
                                  componentRestrictions: { country: "AE" },
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "icon",
                                    "name",
                                  ],
                                  strictBounds: false,
                                  types: ["establishment"],
                                }}
                                defaultValue=""
                                onFocus={(event) => {
                                  event.target.setAttribute(
                                    "autocomplete",
                                    "off"
                                  );
                                }}
                              />
                            </fieldset>
                          </>
                        ) : (
                          <Input
                            label="Coordinates"
                            placeholder="25.2048,55.2708"
                            value={search}
                            onChange={(value) => setSearch(value)}
                          />
                        )}

                        <button
                          type="button"
                          className="btn btn-search mb-0  mt-3 ml-2"
                          onClick={() => onSearchPosition()}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>

                        <button
                          type="button"
                          className="btn btn-theme mb-0  mt-3 ml-2"
                          onClick={() => setSearch("")}
                        >
                          <i className="fa fa-refresh font-13"></i>
                        </button>

                        <span
                          onClick={toggleType}
                          style={styles.toggleButton}
                          data-tip={
                            isAddress ? "Search Co-ordinates" : "Search Address"
                          }
                        >
                          {isAddress ? (
                            <FaMapMarkerAlt size={15} />
                          ) : (
                            <FaRegKeyboard size={15} />
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div
                        className="mt-2"
                        style={{ height: "220px", width: "100%" }}
                      >
                        <DragableMap
                          onDragChange={onSetPosition}
                          position={position}
                          allowUpdateOnChange={true}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {_allow("phoneNumber") && (
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="w-40 mr-3">
                        <Input name="countryCode" label="Country Code" />
                      </div>

                      <div className="w-40 mr-3">
                        <Input
                          value={
                            selectedCustomer ? selectedCustomer.phoneNumber : ""
                          }
                          name="phoneNumber"
                          label="Phone (971xxxxxxxxx)"
                        />
                      </div>

                      <div className="ms-auto">
                        <button
                          type="button"
                          className="btn btn-search mb-0"
                          style={{ marginTop: 15, height: 40 }}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {_allow("taskDate") && (
                  <div className="col-6">
                    <div className="row">
                      <div className="col-md-7">
                        <CalendarField
                          label="Date"
                          selected={date1}
                          onChange={(date) => setDate1(date)}
                        />
                      </div>
                      <div className="col-md-5 d-flex p-1 pt-3 pb-2 float-right">
                        <button
                          type="button"
                          className="btn btn-theme date-button font-10 mb-0 mr-2"
                          onClick={() => setDate1(new Date())}
                        >
                          Today
                        </button>
                        <button
                          type="button"
                          className="btn btn-theme font-10 mb-0"
                          onClick={() => setDate1(tomorrow)}
                        >
                          Tomorrow
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {_allow("time") && (
                  <div className="col-6">
                    <div className="row">
                      <div className="col-md-6 pr-0">
                        <TimePicker
                          value={fromTime}
                          label="From Time"
                          onChange={(date) => setFromTime(date)}
                        />
                      </div>
                      {/* <div className="col-md-4 pr-0" style={{ height: 45 }}>
                        <Select
                          title="Duration"
                          name="duration"
                          onChange={onDurationChange}
                        >
                          <option value="0">0 Minutes</option>
                          <option value="15" selected>
                            15 Minutes
                          </option>
                          <option value="30">30 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="60">1 Hour</option>
                          <option value="120">2 Hour</option>
                          <option value="180">3 Hour</option>
                          <option value="240">4 Hour</option>
                          <option value="300">5 Hour</option>
                        </Select>
                      </div> */}
                      <div className="col-md-6">
                        <TimePicker
                          value={toTime}
                          label="To Time"
                          onChange={(date) => setToTime(date)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Report Scheduler   */}
                {_allow("jobSchedular") && (
                  <div className="col-6">
                    <RepeatTaskModal startDate={date1} />
                  </div>
                )}

                {_allow("priority") && (
                  <div className="col-6">
                    <Select title="Priority" name="priority">
                      <option value="normal">Normal</option>
                      <option value="critical">Urgent</option>
                    </Select>
                  </div>
                )}

                <div className="col-6">
                  <Select
                    title="Status"
                    value={status}
                    name="taskStatus"
                    onChange={onStatusChange}
                  >
                    <TaskStatusList statusList={taskSettings?.taskStatus} />
                  </Select>
                </div>

                <div className="col-6">
                  {_allow("group") && (
                    <div className="mt-2 text-left">
                      <input
                        type="radio"
                        className="radio w-10 ml-2"
                        name="base"
                        value="Driver"
                        checked={staff == "Driver"}
                        onChange={() => {
                          setSelectedDriverGroup(false);
                          if (props.activeDriver) {
                            setSelectedDriver(props.activeDriver);
                          }
                          setStaff("Driver");
                        }}
                      />
                      <span className="mt-1 ml-2 font-14">Field Staff</span>
                      {/* {_allow("group") && ( */}
                      <input
                        type="radio"
                        className="radio w-10 ml-2"
                        name="base"
                        value="Group"
                        checked={staff == "Group"}
                        onChange={() => {
                          setSelectedDriver(false);
                          setStaff("Group");
                        }}
                      />
                      <span className="mt-1 ml-2 font-14">
                        Field Staff Group
                      </span>
                    </div>
                  )}

                  {staff == "Driver" && (
                    <div className="row">
                      <div className="col-md-12">
                        <Select
                          title={`Field Staff`}
                          name="driver"
                          value={selectedDriver ? selectedDriver._id : ""}
                          onChange={onSelectDriver}
                        >
                          <option value=""></option>
                          {taskDrivers.map((driver, i) => (
                            <option key={i} value={driver._id}>
                              {driver.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )}

                  {staff == "Group" && (
                    <div className="Row">
                      <div className="col-md-12">
                        <Select
                          title="Field Staff Group"
                          name="group"
                          onChange={onSelectDriverGroup}
                        >
                          <option value=""></option>
                          {taskDriverGroups.map((group, i) => (
                            <option key={i} value={group._id}>
                              {group.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-md-12 pt-2 d-flex justify-content-between">
                        <div className="text-left">
                          <input
                            type="radio"
                            className="radio w-10 ml-2"
                            name="isSharedWithGroup"
                            value="separately"
                            checked={assignAs == "separately"}
                            onChange={() => {
                              setAssignAs("separately");
                              setStatus("Assigned");
                            }}
                          />
                          <span className=" ml-2 font-14">
                            Assign Separately
                          </span>
                          {/* {_allow("group") && ( */}
                          <input
                            type="radio"
                            className="radio w-10 ml-2"
                            name="isSharedWithGroup"
                            value="asGroup"
                            checked={assignAs == "asGroup"}
                            onChange={() => {
                              setAssignAs("asGroup");
                              setStatus("Assigned To Group");
                            }}
                          />
                          <span className=" ml-2 font-14">Assign As Group</span>
                        </div>
                        <HelpIcon
                          text={
                            assignAs == "asGroup"
                              ? "The tasks will be shared to the selected group, so all the members will receive the task and only one can start the task. Other members can't access the task after that"
                              : "It will assign the task directly to the Filed Staff"
                          }
                          className="my-auto"
                          size={20}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* )} */}

                {_allow("startAddress") && (
                  <div className="col-6">
                    {/* <AddressField
                      name="startAddress"
                      label="From Address"
                      value={address}
                      placeholder="Enter a Location"
                      wantMap={true}
                    /> */}
                    <div className="pointer" onClick={toggleStartAddressModel}>
                      <Input
                        disabled
                        label="From Address"
                        name="startAddress"
                        type="text"
                        value={startAddress}
                      />
                    </div>
                    <StaticMap
                      lat={startPosition.lat}
                      lng={startPosition.lng}
                      style={{
                        height: 200,
                        borderRadius: 5,
                        top: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                )}

                {_allow("endAddress") && (
                  <div className="col-6">
                    {/* <AddressField
                      name="endAddress"
                      label="To Address"
                      placeholder="Enter a Location"
                      value={dropAddress}
                      wantMap={true}
                    /> */}
                    <div className="pointer" onClick={toggleEndAddressModel}>
                      <Input
                        disabled
                        label="End Address"
                        name="endAddress"
                        type="text"
                        value={endAddress}
                      />
                    </div>
                    <StaticMap
                      lat={endPosition.lat}
                      lng={endPosition.lng}
                      style={{
                        height: 200,
                        borderRadius: 5,
                        top: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                )}

                {/* DROP & PICKUP */}

                {_allow("dropAndPickup") && (
                  <div className="row m-0">
                    <div className="col-6">
                      <div
                        className="mt-3"
                        style={{ height: "250px", width: "100%" }}
                      >
                        <p className="text-left"> Pickup Location </p>
                        <DragableMap
                          onDragChange={onSetPickupPosition}
                          position={pickupPosition}
                          allowUpdateOnChange={true}
                        />
                      </div>

                      <div className="d-flex mt-5">
                        {isAddress ? (
                          <>
                            <fieldset className="pb-0">
                              <legend>
                                <span>Address</span>
                              </legend>
                              <Autocomplete
                                apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
                                onPlaceSelected={(place) =>
                                  handleGoogleSearch(place, "pickup")
                                }
                                className="form-control p-0 font-12 box-shadow-none"
                                placeholder="Search here..."
                                options={{
                                  componentRestrictions: { country: "AE" },
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "icon",
                                    "name",
                                  ],
                                  strictBounds: false,
                                  types: ["establishment"],
                                }}
                                defaultValue=""
                                onFocus={(event) => {
                                  event.target.setAttribute(
                                    "autocomplete",
                                    "off"
                                  );
                                }}
                              />
                            </fieldset>
                          </>
                        ) : (
                          <Input
                            label="Coordinates"
                            placeholder="25.2048,55.2708"
                            value={pickupLocationPositionValue}
                            onChange={(value) =>
                              setPickupLocationPositionValue(value)
                            }
                          />
                        )}
                        <button
                          type="button"
                          className="btn btn-search mb-0  mt-3 ml-2"
                          onClick={() => onSearchPosition("pickup")}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-theme mb-0  mt-3 ml-2"
                          onClick={() => {
                            setPickupLocationPositionValue("");
                            setIsAddress("");
                          }}
                        >
                          <i className="fa fa-refresh font-13"></i>
                        </button>
                        <span
                          onClick={toggleType}
                          style={styles.toggleButton}
                          data-tip={
                            isAddress ? "Search Co-ordinates" : "Search Address"
                          }
                        >
                          {isAddress ? (
                            <FaMapMarkerAlt size={15} />
                          ) : (
                            <FaRegKeyboard size={15} />
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div
                        className="mt-3"
                        style={{ height: "250px", width: "100%" }}
                      >
                        <p className="text-left"> Drop Location </p>
                        <DragableMap
                          onDragChange={onSetDropPosition}
                          position={dropPosition}
                          allowUpdateOnChange={true}
                        />
                      </div>

                      <div className="d-flex mt-5">
                        {isAddress ? (
                          <>
                            <fieldset className="pb-0">
                              <legend>
                                <span>Address</span>
                              </legend>
                              <Autocomplete
                                apiKey={GOOGLE_MAPS_API_KEY.MAIN_KEY}
                                onPlaceSelected={(place) =>
                                  handleGoogleSearch(place, "drop")
                                }
                                className="form-control p-0 font-12 box-shadow-none"
                                placeholder="Search here..."
                                options={{
                                  componentRestrictions: { country: "AE" },
                                  fields: [
                                    "address_components",
                                    "geometry",
                                    "icon",
                                    "name",
                                  ],
                                  strictBounds: false,
                                  types: ["establishment"],
                                }}
                                defaultValue=""
                                onFocus={(event) => {
                                  event.target.setAttribute(
                                    "autocomplete",
                                    "off"
                                  );
                                }}
                              />
                            </fieldset>
                          </>
                        ) : (
                          <Input
                            label="Coordinates"
                            placeholder="25.2048,55.2708"
                            value={dropLocationPositionValue}
                            onChange={(value) =>
                              setDropLocationPositionValue(value)
                            }
                          />
                        )}
                        <button
                          type="button"
                          className="btn btn-search mb-0  mt-3 ml-2"
                          onClick={() => onSearchPosition("drop")}
                        >
                          <i className="fa fa-search font-14"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-theme mb-0  mt-3 ml-2"
                          onClick={() => {
                            setDropLocationPositionValue("");
                            setIsAddress(false);
                          }}
                        >
                          <i className="fa fa-refresh font-13"></i>
                        </button>
                        <span
                          onClick={toggleType}
                          style={styles.toggleButton}
                          data-tip={
                            isAddress ? "Search Co-ordinates" : "Search Address"
                          }
                        >
                          {isAddress ? (
                            <FaMapMarkerAlt size={15} />
                          ) : (
                            <FaRegKeyboard size={15} />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {taskAttributes &&
                  taskAttributes.map((attribute, i) => {
                    return (
                      <div key={i} className="col-6">
                        <MakeAttribute key={i} attribute={attribute} />
                      </div>
                    );
                  })}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              style={{ fontSize: "12px" }}
              className="btn btn-small"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
              disabled={isLoading}
              id="createTaskButton"
            >
              Submit Task
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <ReactTooltip className="tooltip" />
    </>
  );
};

export default CreateTaskModal;

const styles = {
  toggleButton: {
    marginBlock: "auto",
    marginTop: "1rem",
    backgroundColor: "#f2f2f2",
    borderRadius: 3,
    cursor: "pointer",
    padding: "8px 12px",
    marginLeft: 5,
  },
};
