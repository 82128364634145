import axios from "axios";
import { API_URLS } from "../configs/api.urls";
import { getStorage } from "./localStorageService";

const API_URL = API_URLS.REPORTS_API;

export const makeId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const NodeReportsRequest = async (
  path,
  method = "GET",
  data = null,
  headerData = false
) => {
  const activeUser = getStorage("__user__", "object");
  var token = activeUser
    ? makeId(100) + "__" + activeUser.organisation.id + "__" + activeUser.id
    : "UnAuthorized";
  const headers = { "X-master-auth": token, "client-type": "locatorWeb" };

  if (headerData) {
    headers["content-type"] = "application/json";
  }

  try {
    const config = {
      url: `${API_URL}${path}`,
      method,
      headers,
      data,
      withCredentials: true,
    };

    const response = await axios(config);

    const { statusCode, success, response: message } = response.data;

    if (statusCode === 200 && success) {
      return response.data.data;
    }

    if (statusCode === 401) {
      throw new Error("Unauthenticated");
    }

    throw new Error(message || "Unexpected error occurred");
  } catch (error) {
    throw new Error(error.message || "Request failed");
  }
};

export default NodeReportsRequest;
