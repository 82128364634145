import TaskManagerRequest from "../../services/TaskManagerRequest";

export const SET_COMMUNITY_DRIVER_GROUPS = "SET_COMMUNITY_DRIVER_GROUPS";
export const SET_COMMUNITY_GROUPS = "SET_COMMUNITY_GROUPS";
export const SET_COMMUNITY_DRIVERS = "SET_COMMUNITY_DRIVERS";
export const SET_ACTIVE_COMMUNITY_DRIVER = "SET_ACTIVE_COMMUNITY_DRIVER";
export const GET_COMMUNITY_TRIPS_SUMMARY = "GET_COMMUNITY_TRIPS_SUMMARY";

let isFirstCall = true;

export const getCommunityDriverGroups = (updatedDriver=null, isDelete=false) => {
  return async (dispatch) => {
    let groups = []
    try {
      groups = await TaskManagerRequest("/community/groups");
      dispatch({
        type: SET_COMMUNITY_GROUPS,
        value: groups,
      });
    } catch (error) {
      dispatch({
        type: SET_COMMUNITY_GROUPS,
        value: groups,
      });
    }

    let drivers = [];
    try {
      drivers = await TaskManagerRequest("/community/drivers");
      dispatch({
        type: SET_COMMUNITY_DRIVERS,
        value: drivers,
      });
    } catch (error) {
      dispatch({
        type: SET_COMMUNITY_DRIVERS,
        value: drivers,
      });
    }

    const driverGroups = [];
    let activeDriver = null;
    

    // conditions for setting active driver on first call, on deletion and updation.
    if (
      Array.isArray(groups) &&
      Array.isArray(drivers)
    ) {
      groups?.forEach((group) => {
        const driverGroup = drivers.filter(
          (driver) => driver?.group?._id === group._id
        );
        driverGroups.push({
          ...group,
          drivers: driverGroup,
        });

        if(isFirstCall || isDelete){
          if (!activeDriver && driverGroup?.length > 0) {
            activeDriver = driverGroup[0];
            dispatch({
              type: SET_ACTIVE_COMMUNITY_DRIVER,
              value: activeDriver,
            });
            isFirstCall = false;
          }
        }else if(!isFirstCall && updatedDriver){
          dispatch({
            type: SET_ACTIVE_COMMUNITY_DRIVER,
            value: updatedDriver,
          });
        }
      });
    }

    dispatch({
      type: SET_COMMUNITY_DRIVER_GROUPS,
      value: driverGroups,
    });
  };
};

export const setActiveDriver = (driver) => {
  return {
    type: SET_ACTIVE_COMMUNITY_DRIVER,
    value: driver,
  };
};

export const getCommunityTripsSummary = (driverUniqueId) => {
    return async dispatch => {
      try {

        dispatch({
          type: GET_COMMUNITY_TRIPS_SUMMARY,
          value: {},
        });

        const summary = await TaskManagerRequest("/community/trips/summary", {driverUniqueId});
        dispatch({
          type: GET_COMMUNITY_TRIPS_SUMMARY,
          value: summary,
        });
      } catch (error) {
        console.log("Error in fetching community trips summary", error)
      }
    }
}
