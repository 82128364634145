import {
  GET_REPORT_GROUP_NAMES,
  SET_GROUPS,
  REPORT_TOGGLE_SELECTED_VEHICLE,
  REPORT_TOGGLE_SELECTED_VEHICLE_GROUP,
  GET_REPORT_NAMES,
  SET_DISTANCE_REPORT,
  TOGGLE_REPORT_LODER,
  SET_DETAILED_REPORT,
  SET_IDLING_REPORT,
  SET_TRIP_AND_PARKING_REPORT,
  SET_DAILY_ATTANDANCE_REPORT,
  SET_FULE_COST_REPORT,
  SET_EXCESSIVE_IDLING_REPORT,
  SET_OFFICE_REPORT,
  SET_SERVICE_ENTRY_REPORT,
  SET_FULE_ENTRY_REPORT,
  SET_DOCUMENT_REMINDER_REPORT,
  SET_NEW_SALIK_REPORT,
  SET_SEAT_VIOLATION_REPORT,
  SET_DUTY_TIME_REPORT,
  SET_SUMMARY_IDLING_REPORT,
  SET_CHECK_IN_REPORT,
  SET_ROUTE_REPORT,
  SET_DRIVER_REPORT,
  SET_TRIP_REPORT,
  SET_PARKING_REPORT,
  SET_ZONE_CALLS_REPORT,
  SET_DRIVER_BEHAVIOUR_REPORT,
  SET_AC_WORKING_REPORT,
  SET_SHOW_MAP,
  SET_SPEED_REPORT,
  SET_SERVICE_REMINDER_REPORT,
  SET_SUMMARY_FUEL_COST_REPORT,
  SET_SUMMARY_DRIVER_IDLING_REPORT,
  SET_GEOZONE_REPORT,
  SET_DRIVER_ATTENDANCE_REPORT,
  SET_SUMMARY_DRIVER_REPORT,
  SET_DRIVER_BASED_REPORT,
  SET_WEEKLY_COMPARISION_REPORT,
  SET_SUMMARY_GRAPHICAL_REPORT,
  SET_IDLING_WITH_DRIVER_REPORT,
  SET_FUEL_GRAPH_CAN_REPORT,
  SET_FUEL_GRAPH_CAN_IN_GALLON_REPORT,
  SET_TASK_MANAGER_REPORT,
  SET_ACTIVE_IDLING,
  SET_ACTIVE_PARKING,
  SET_REPORT_DATA,
  SET_REPORT_DATA_FOR_PDF,
  REPORT_REMOVE_SELECTED_VEHICLE,
  SET_SUMMARY_DISTANCE_REPORT,
  SET_POI_REPORT,
  SET_ATTENDANCE_REPORT_MVL,
  SET_DOOR_STATUS_REPORT,
  SET_ASSETS_REPORT,
  SET_DISTANCE_REPORT_POI,
  SET_ASPEN_REPORT,
  SET_ASSETS_REPORT_EXCLUSIVE_SUNDAY,
  SET_DRIVER_ATTENDANCE_OVERTIME_REPORT,
  SET_DRIVER_OVERTIME_REPORT,
  SET_HOLIDAY_TRIP_REPORT,
  SET_ODOMETER_REPORT,
  SET_NEW_ODOMETER_REPORT,
  SET_WEIGHT_REPORT,
  SET_WEIGHT_REPORT_THORE,
  SET_DAILY_ASSET_REPORT,
  SET_MILEAGE_REPORT_CAN,
  SET_SUMMARY_GRAPHICAL_REPORT_WITH_WEIGHT,
  SET_SAMSUNG_TASK_MANAGER_REPORT,
  SET_DAILY_TRIP_SUMMARY_BETA_REPORT,
  SET_DRIVER_BASED_DETAILED_REPORT,
  SET_TEMPRATURE_REPORT,
  SET_GRAPHICAL_ATTENDANCE,
  SET_GRAPHICAL_SALIK_DARB,
  SET_HOLIDAY_REPORT_WITH_FUEL_COST,
  SET_TRIP_BASED_FUEL_CONSUMPTION_REPORT,
  SET_DRIVER_RAG_REPORT,
  SET_DRIVER_BASED_IDLING_REPORT,
  SET_DRIVER_BASED_AFTER_OFFICE_HOURS_REPORT
} from "../actions/report.action";

const curruntDate = new Date().setHours(0, 0, 0, 0);
const curruntEndDate = new Date().setHours(23, 59, 59, 59);

const initialState = {
  groups: [],
  selectedVehicles: [],
  reportGroupNames: [],
  reportNames: [],
  distanceReports: [],
  detailedReports: [],
  idlingReports: [],
  activeIdling: false,
  activeParking: false,
  geozoneReports: [],
  tripAndParkingReport: [],
  dailyAttandanceReport: [],
  fuleCostReport: [],
  excessiveIdlingReports: [],
  officeReport: [],
  serviceEntryReport: [],
  fuleEntryReport: [],
  speedReport: [],
  documentReminderReport: [],
  driverAttendanceReport: [],
  assetReports: [],
  driverBasedReport: [],
  newSalikReport: [],
  seatViolationReport: [],
  dutyTimeReport: [],
  summaryIdlingReport: [],
  checkInReports: [],
  reportRoutes: [],
  driverReports: [],
  tripsReports: [],
  parkingsReports: [],
  zoneCallsReports: [],
  driverBehaviourReport: [],
  acWorkingReports: [],
  summaryFuelCostReport: [],
  summaryDriverIdlingReport: [],
  summaryDriverIdlingReport: [],
  summaryDriverReport: [],
  weeklyComparisionReport: [],
  summaryGraphicalReport: [],
  idlingWithDriverReport: [],
  fuelGraphCANReport: [],
  taskManagerReport: [],
  summaryDistanceReport: [],
  poiReports: [],
  attendanceReportsMVL: [],
  distanceReportsPOI: [],
  doorStatusReports: [],
  aspenReports: [],
  assetReportsExclusiveSunday: [],
  driverAttendanceOvertimeReport: [],
  driverOvertimeReport: [],
  holidayTripReport: [],
  holidayReportWithFuelCost: [],
  odometerReport: [],
  newOdometerReport: [],
  weightReport: [],
  weightReportThore: [],
  dailyAssetReport: [],
  mileageReportCAN: [],
  summaryGraphicalReportWithWeight: [],
  samsungTaskManagerReport: [],
  driverBasedDetailedReport: [],
  tempratureReports: [],
  dailyTripSummaryBeta: [],
  showReportLoader: false,
  totalFoundedReport: 0,
  isShowMap: false,
  reportData: {
    startDate: curruntDate,
    endDate: curruntEndDate,
    reportName: "Distance Report\r\n",
    reportName2: "Summary Graphical Report",
    activeTab: "Today",
  },
  activeReportData: null,
  graphicalAttendanceData: [],
  salikAndDarbReport: [],
  tripBasedFuelConsumptionReports: [],
  driverRagReport: [],
  driverBasedIdlingReport: [],
  driverBasedAfterOfficeHoursReport: []
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return { ...state, groups: action.value, checkedVehicles: [] };

    case REPORT_TOGGLE_SELECTED_VEHICLE:
      const cloneData = [...state.selectedVehicles];
      var i = cloneData.findIndex((v) => v.id == action.value.id);
      if (i == -1) {
        cloneData.push(action.value);
      } else {
        cloneData.splice(i, 1);
      }
      return { ...state, selectedVehicles: cloneData };

    case REPORT_TOGGLE_SELECTED_VEHICLE_GROUP:
      const cloneForVehicleGroup = [...state.groups];
      var v = [...state.selectedVehicles];

      var i = cloneForVehicleGroup.findIndex((g) => g.id == action.value.id);

      if (i !== -1) {
        cloneForVehicleGroup[i].vehicles.map((veh) => {
          var exist = v.findIndex((v) => v.id == veh.id);

          if (exist == -1 && action.value.checked == true) {
            v.push(veh);
          } else if (exist !== -1 && action.value.checked == true) {
          } else {
            v.splice(exist, 1);
          }
        });

        // Object.keys(cloneForVehicleGroup[i].__vehicles__).map((key) => {

        //     var exist = v.findIndex((v) => v.id == key);

        //     if (exist == -1 && action.value.checked == true) {
        //         v.push(cloneForVehicleGroup[i].__vehicles__[key])
        //     } else if (exist !== -1 && action.value.checked == true) {

        //     } else {
        //         v.splice(exist, 1)
        //     }

        // })
      }

      return {
        ...state,
        selectedVehicles: v,
      };

    case REPORT_REMOVE_SELECTED_VEHICLE:
      return { ...state, selectedVehicles: action.value };

    case GET_REPORT_GROUP_NAMES:
      return { ...state, reportGroupNames: action.value };

    case GET_REPORT_NAMES:
      return { ...state, reportNames: action.value };

    case SET_DISTANCE_REPORT:
      return {
        ...state,
        distanceReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DETAILED_REPORT:
      return {
        ...state,
        detailedReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_IDLING_REPORT:
      return {
        ...state,
        idlingReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ACTIVE_IDLING:
      return {
        ...state,
        activeIdling: action.value,
      };

    case SET_ACTIVE_PARKING:
      return {
        ...state,
        activeParking: action.value,
      };

    case SET_GEOZONE_REPORT:
      return {
        ...state,
        geozoneReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_OVERTIME_REPORT:
      return {
        ...state,
        driverOvertimeReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_POI_REPORT:
      return {
        ...state,
        poiReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_TEMPRATURE_REPORT:
      return {
        ...state,
        tempratureReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_TRIP_AND_PARKING_REPORT:
      return {
        ...state,
        tripAndParkingReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_BASED_DETAILED_REPORT:
      return {
        ...state,
        driverBasedDetailedReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DAILY_ATTANDANCE_REPORT:
      return {
        ...state,
        dailyAttandanceReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DAILY_ASSET_REPORT:
      return {
        ...state,
        dailyAssetReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_MILEAGE_REPORT_CAN:
      return {
        ...state,
        mileageReportCAN: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_FULE_COST_REPORT:
      return {
        ...state,
        fuleCostReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_HOLIDAY_TRIP_REPORT:
      return {
        ...state,
        holidayTripReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_HOLIDAY_REPORT_WITH_FUEL_COST:
      return {
        ...state,
        holidayReportWithFuelCost: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ODOMETER_REPORT:
      return {
        ...state,
        odometerReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_NEW_ODOMETER_REPORT:
      return {
        ...state,
        newOdometerReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_EXCESSIVE_IDLING_REPORT:
      return {
        ...state,
        excessiveIdlingReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_OFFICE_REPORT:
      return {
        ...state,
        officeReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SERVICE_ENTRY_REPORT:
      return {
        ...state,
        serviceEntryReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_WEIGHT_REPORT:
      return {
        ...state,
        weightReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_WEIGHT_REPORT_THORE:
      return {
        ...state,
        weightReportThore: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_FULE_ENTRY_REPORT:
      return {
        ...state,
        fuleEntryReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SPEED_REPORT:
      console.log("action.value......", action.value);
      return {
        ...state,
        speedReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DOCUMENT_REMINDER_REPORT:
      return {
        ...state,
        documentReminderReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SERVICE_REMINDER_REPORT:
      return {
        ...state,
        serviceReminderReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_BASED_REPORT:
      return {
        ...state,
        driverBasedReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ASPEN_REPORT:
      var clone = [...action.value];

      clone.map((v) => {
        v.vehicles.map((vh) => {
          (vh.groupname = v.groupname), (vh.deviceid = v.deviceid);
        });
      });

      return {
        ...state,
        aspenReports: clone,
        totalFoundedReport: action.value.length,
      };

    case SET_NEW_SALIK_REPORT:
      return {
        ...state,
        newSalikReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ASSETS_REPORT_EXCLUSIVE_SUNDAY:
      return {
        ...state,
        assetReportsExclusiveSunday: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_ATTENDANCE_OVERTIME_REPORT:
      return {
        ...state,
        driverAttendanceOvertimeReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SEAT_VIOLATION_REPORT:
      return {
        ...state,
        seatViolationReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DUTY_TIME_REPORT:
      return {
        ...state,
        dutyTimeReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SAMSUNG_TASK_MANAGER_REPORT:
      return {
        ...state,
        samsungTaskManagerReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SUMMARY_GRAPHICAL_REPORT:
      return {
        ...state,
        summaryGraphicalReport: action.value,
        totalFoundedReport: action.value.filter(
          (item) => parseFloat(item.totaldistance) > 0
        ).length,
      };

    case SET_SUMMARY_GRAPHICAL_REPORT_WITH_WEIGHT:
      return {
        ...state,
        summaryGraphicalReportWithWeight: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SUMMARY_IDLING_REPORT:
      return {
        ...state,
        summaryIdlingReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_CHECK_IN_REPORT:
      return {
        ...state,
        checkInReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ROUTE_REPORT:
      return {
        ...state,
        reportRoutes: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_REPORT:
      return {
        ...state,
        driverReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_TRIP_REPORT:
      return {
        ...state,
        tripsReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_PARKING_REPORT:
      return {
        ...state,
        parkingsReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ZONE_CALLS_REPORT:
      return {
        ...state,
        zoneCallsReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_BEHAVIOUR_REPORT:
      return {
        ...state,
        driverBehaviourReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_AC_WORKING_REPORT:
      return {
        ...state,
        acWorkingReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SUMMARY_FUEL_COST_REPORT:
      return {
        ...state,
        summaryFuelCostReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SUMMARY_DRIVER_IDLING_REPORT:
      return {
        ...state,
        summaryDriverIdlingReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SUMMARY_DRIVER_REPORT:
      return {
        ...state,
        summaryDriverReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_ATTENDANCE_REPORT:
      return {
        ...state,
        driverAttendanceReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ASSETS_REPORT:
      return {
        ...state,
        assetReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DISTANCE_REPORT_POI:
      return {
        ...state,
        distanceReportsPOI: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_WEEKLY_COMPARISION_REPORT:
      return {
        ...state,
        weeklyComparisionReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_IDLING_WITH_DRIVER_REPORT:
      return {
        ...state,
        idlingWithDriverReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_FUEL_GRAPH_CAN_REPORT:
    case SET_FUEL_GRAPH_CAN_IN_GALLON_REPORT:
      return {
        ...state,
        fuelGraphCANReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_TASK_MANAGER_REPORT:
      return {
        ...state,
        taskManagerReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_SUMMARY_DISTANCE_REPORT:
      return {
        ...state,
        summaryDistanceReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DAILY_TRIP_SUMMARY_BETA_REPORT:
      return {
        ...state,
        dailyTripSummaryBeta: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_ATTENDANCE_REPORT_MVL:
      return {
        ...state,
        attendanceReportsMVL: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DOOR_STATUS_REPORT:
      return {
        ...state,
        doorStatusReports: action.value,
        totalFoundedReport: action.value.length,
      };

    case TOGGLE_REPORT_LODER:
      return {
        ...state,
        showReportLoader: action.value,
      };

    case SET_SHOW_MAP:
      return {
        ...state,
        isShowMap: action.value,
      };

    case SET_REPORT_DATA:
      const data = { ...state.reportData, ...action.value };

      return {
        ...state,
        reportData: data,
      };

    case SET_REPORT_DATA_FOR_PDF:
      return {
        ...state,
        activeReportData: action.value,
      };

    case SET_GRAPHICAL_ATTENDANCE:
      return {
        ...state,
        graphicalAttendanceData: action.value,
      };

    case SET_GRAPHICAL_SALIK_DARB:
      return {
        ...state,
        salikAndDarbReport: action.value,
      };

    case SET_TRIP_BASED_FUEL_CONSUMPTION_REPORT:
      return {
        ...state,
        tripBasedFuelConsumptionReports : action?.value,
        totalFoundedReport : action?.value?.length
      }

    case SET_DRIVER_RAG_REPORT:
      return {
        ...state,
        driverRagReport: action.value,
        totalFoundedReport: action.value.length,
      };

    case SET_DRIVER_BASED_IDLING_REPORT : 
      return {
        ...state,
        driverBasedIdlingReport : action.value,
        totalFoundedReport : action.value.length,
      }

    case SET_DRIVER_BASED_AFTER_OFFICE_HOURS_REPORT:
      return{
        ...state,
        driverBasedAfterOfficeHoursReport : action.value,
        totalFoundedReport : action.value.length,
      }

    default:
      return state;
  }
};

export default ReportReducer;
